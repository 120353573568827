import React from "react";
import "./Footer.scss";

function Footer({titre}) {
  return <div>
    <div className="hidden-xs col-sm-50 col-sm-offset-10 col-lg-45 col-lg-offset-15 cn">
        Centre National<br />
        de Création Musicale<br />
        Albi — Tarn
    </div>
    <div className="hidden-xs col-sm-50 col-lg-45 adr">
      4 rue Sainte-Claire<br />
      81 000 Albi / France<br />
      +33 (0)5 63 54 51 75<br />
      info@gmea.net
    </div>
  </div>;
}

export default Footer;
