import React, {useContext, useMemo, useEffect } from "react";
import "./Presentation.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Conf from 'Conf';
import { useParams } from "react-router-dom";
import {
  Link,
} from "react-router-dom";

function Presentation() {
  const { id:presentationId } = useParams();
  const { getDoc, getCollection } = useContext(ModelContext);
  const { setAppState } = useContext(SettingsContext);
  const presentation=useMemo(()=>getDoc(presentationId),[getDoc,presentationId]);
  useEffect(()=>{
    if (presentation) setAppState((state)=>{return {...state,currentPresentation:presentation} })
    return ()=>{
      setAppState((state)=>{return {...state,currentPresentation:null} })
    }
  },[presentation,setAppState]);
  const chapitres=useMemo(()=>getCollection('chapitre').filter((o)=>o.presentationId===presentationId),[getCollection,presentationId]);
  return presentation ? <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-lg-90 col-lg-offset-15 presentation">
		<div className="eve-container hidden-xs">
      {chapitres.map((chapitre)=><div key={chapitre._id} className="col-xs-30 evt-vignette activites chapitre">
        <Link to={"/chapitre/"+chapitre._id}>
        <div className="ratio3">
            <div className="strech highlight" style={{backgroundColor:chapitre.couleur}}>
                <div className="ratioimg">
                  <div className="vignette-img" style={{backgroundImage:chapitre.image[0] ? "url("+Conf.filesUrl+chapitre.image[0].url+"-600)": null}}></div>
                </div>
                <div className="col-xs-120 evediv">
              		<p className="evetitre upper">
              			{chapitre.titre}
              		</p>
              	</div>
            </div>
        </div>
        </Link>
      </div>)}
    </div>
  </div> : null;
}

export default Presentation;
