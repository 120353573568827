import React, {useContext } from "react";
import "./Header.scss";
import Logo from 'components/assets/logo_n.svg';
import {
  Link,
} from "react-router-dom";
import { SettingsContext } from "providers/SettingsProvider";

function Header({titre}) {
  const { appState } = useContext(SettingsContext);
  return <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-lg-90 col-lg-offset-15 header">
  	<div className="logo col-xs-120 col-sm-40 col-lg-35">
  		<Link to="/"><div className="ratio1">
  			<img className="img-responsive" src={Logo} alt='gmea'/>
  			<img className="img-responsive" src={Logo} alt='gmea'/>
  			<img className="img-responsive" src={Logo} alt='gmea'/>
  		</div></Link>
  	</div>
  	<div className="menu col-xs-120 col-sm-80 col-lg-85">
  		<div>
        {appState.currentPresentation && <Link to={"/presentation/"+appState.currentPresentation._id}>{appState.currentPresentation.titre}</Link>}
        {appState.currentChapitre ? <span> | {appState.currentChapitre.titre}</span> : null}
      </div>
  	</div>
  	<div className="clearfix"></div>
  </div>;
}

export default Header;
