import React, {useContext} from "react";
import "./Landing.scss";
import { ModelContext } from "providers/ModelProvider";
import {
  Link,
} from "react-router-dom";

function Landing() {
  const { getCollection } = useContext(ModelContext);
  const presentations=getCollection('presentation');
  return <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-lg-90 col-lg-offset-15 landing">
    {presentations.map((presentation)=><div key={presentation._id} className="presentation-titre"><Link to={"/presentation/"+presentation._id}>{presentation.titre}</Link></div>)}
  </div>;
}

export default Landing;
