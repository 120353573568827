import React from "react";
import './App.scss';

import Landing from 'components/Landing';
import Presentation from 'components/Presentation';
import Chapitre from 'components/Chapitre';
import Header from 'components/Header';
import Footer from 'components/Footer';

import {
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return <div className="main-container">
    <Header/>
    <div className="content-container">
      <Routes>
        <Route path="/chapitre/:id" element={<Chapitre />} />
        <Route path="/presentation/:id" element={<Presentation />} />
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<Landing />} />
      </Routes>
    </div>
    <Footer/>
  </div>;
}

export default App;
