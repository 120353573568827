import React, { useContext, useMemo, useRef, useEffect, useCallback } from "react";
import "./Chapitre.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Conf from 'Conf';
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Navigation, Keyboard
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
SwiperCore.use([Navigation,Keyboard]);

var goFullscreen=function(elem){
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
}
var exitFullscreen=function(){
    if(document.exitFullscreen) {
      document.exitFullscreen();
    } else if(document.msCancelFullScreen) {
      document.msCancelFullScreen();
    } else if(document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if(document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
}
const stopVideos=()=>{
  console.log('stopVideos');
  document.querySelectorAll('video').forEach((video, i) => {
    video.pause();
  });
}
function Chapitre() {
  const { id:chapitreId } = useParams();
  const { getCollection, getDoc } = useContext(ModelContext);
  const { setAppState } = useContext(SettingsContext);
  const chapitre=useMemo(()=>getDoc(chapitreId),[getDoc,chapitreId]);
  useEffect(()=>{
    if (chapitre) {
      const presentation=getDoc(chapitre.presentationId);
      if (presentation) {
        setAppState((state)=>{return {...state,currentPresentation:presentation, currentChapitre:chapitre} })
      }
      return ()=>{
        setAppState((state)=>{return {...state,currentPresentation:null, currentChapitre:null} })
      }
    }
  },[chapitre,setAppState,getDoc]);
  const slides=useMemo(()=>getCollection('slide').filter((o)=>o.chapitreId===chapitreId),[getCollection,chapitreId]);
  const el=useRef(null);
  const toggleFullscreen=useCallback(()=>{
    if (el.current) {
      if ( document.fullscreen ) exitFullscreen();
      else goFullscreen(el.current);
    }
  },[]);
  useEffect(()=>{
    const handleKeypress=(e)=>{
      if(e.keyCode===70) toggleFullscreen();
    };
    window.addEventListener('keydown',handleKeypress);
    return ()=>{
      window.removeEventListener('keydown',handleKeypress);
    }
  },[toggleFullscreen]);
  return chapitre ? <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-lg-90 col-lg-offset-15 chapitre">
    <div className="eve-container ratio2">
    {slides.length>0 && <div ref={el}>
        <Swiper navigation={{prevEl:".swiper-button-prev",nextEl:".swiper-button-next"}} keyboard onSlideChange={stopVideos}>
  		    {slides.map((slide)=><SwiperSlide key={slide._id}>
            {slide.media[0] && slide.media[0].type.startsWith('image/') && <div className="slide" style={{backgroundImage:"url("+Conf.filesUrl+slide.media[0].url+"-fhd)"}}>
              {slide.texte && <div className="diap-credits">{slide.texte}</div>}
            </div>}
            {slide.media[0] && slide.media[0].type.startsWith('video/') && <video className="slide"
              src={Conf.filesUrl+slide.media[0].url}
              controls
            />}
          </SwiperSlide>)}
        </Swiper>
        <div className="swiper-button-prev evt"></div>
        <div className="swiper-button-next evt"></div>
        <div className="fullscreen-button" onClick={toggleFullscreen}></div>
      </div>}
      <div className="col-xs-120 inter"></div>
    </div>
    <div className="col-xs-120 inter"></div>
	</div> : null;
}

export default Chapitre;
